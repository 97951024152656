import React from 'react';
import './../../styles/global.scss';
import Layout from './../../components/Layout';
import {makeStyles, Card} from '@material-ui/core';
import {graphql, Link} from 'gatsby'
import {GatsbyImage} from "gatsby-plugin-image";
import {Helmet} from 'react-helmet';
import firebase from "gatsby-plugin-firebase"

const useStyle = makeStyles({
    
    blog:{
        marginTop:150,
        display:"grid",
        gap:"1rem",
        justifyContent:"center",
        gridTemplateColumns: "repeat(auto-fit,minmax(240px,1fr))",
        gridAutoRows:284,

        
        
        "& a":{
            width:"100%",
        }

    },
    article:{
        backgroundColor:"#032E3C",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start",
        height:"100%",
        borderRadius:10,
        width:"100%",
        boxShadow: "rgba(2, 8, 20, 0.05) 0px 0.175em 0.5em",

        "&:hover" :{
            boxShadow: "rgba(2, 8, 20, 0.3) 0px 0.35em 1.175em",
            transform: "translateY(-0.2px) scale(1.01)",
         },
        

    },
    cover:{
        borderRadius:"7px",
        marginTop:"4%",
        width:"90%",
        objectFit:"cover",
        minHeight:"50%",

    },

    infosContainer:{
        width:"90%",
        marginTop:25,
        marginBottom:10,
        display:'flex',
        justifyContent:"space-between",
        flexDirection:"column",
        
        flexGrow:1,


        "& h4":{
            fontSize: 17,
            color:"#FFFFFF",
            margin:0,
            marginBottom:10,
        },
        "& p":{
           
            fontWeight: 400,
            fontSize: 14,
            margin:0,
            marginLeft:"auto",
            color:"#EAEAEA",
        }
    },


})


const registerEvent= (event) =>{
    firebase.analytics().logEvent(`blog-${event}-read`);
}

function Index({data}) {

    const blog = data.allContentfulBlog.nodes;

    const classes = useStyle();

    

    return (
        <div>
            <Helmet>
                <title>Blog</title>
                <link rel="canonical" href="https://www.momar.dev/blog" />
            </Helmet>
            <Layout>
                <div className={classes.blog}>
                {
                    blog.map((article) => {
                        
                        return <Link onClick={()=>registerEvent(article.title)} to={`/blog/${article.slug}`} key={article.id}>
                                <Card className={classes.article}>
                                <GatsbyImage className={classes.cover} image={article.cover.gatsbyImageData} alt="image-blog"/>
                                <div className={classes.infosContainer}>
                                    <h4>{article.title}</h4>
                                    <p>{article.updatedAt}</p>
                                </div>
                                </Card>
                            </Link>
                    })
                }
                </div>
    
            </Layout>
            
        </div>
    );
}


export const query = graphql`
  {
    allContentfulBlog(sort: {fields: updatedAt, order: DESC}) {
      nodes {
        cover {
          gatsbyImageData(placeholder: BLURRED)
        }
        updatedAt(formatString: "MMM DD, YYYY")
        id
        slug
        title
      }
    }
  }
`


export default Index;